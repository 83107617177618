export default (displayName, options = [], name, defaultValue) => ({
  init() {
    this.$watch('selectedValue', (value) => {
      console.log(this.$refs['opener-'+name].clientWidth,'value')
      if(this.$refs['opener-'+name].clientWidth < 200){
        this.openerWidth = 200
        return
      }
        if (value) {
            this.openerWidth = this.$refs['opener-'+name].clientWidth;
        }
    });
    this.$watch('open', (value) => {
      if(this.$refs['opener-'+name].clientWidth < 200){
        this.openerWidth = 200
        return
      }
      if (value) {
          this.openerWidth = this.$refs['opener-'+name].clientWidth;
      }
  });
},
  options: options,
  open: false,
  displayName: displayName,
  selectedValue: options.find((o) => o.value === defaultValue),
  openerWidth: 200,
  change(val) {
    this.$dispatch(name, val);
    this.selectedValue = options.find((o) => o.value === val);
    this.open = false;
  },
});

import { GraphQLClient, gql } from "graphql-request";
import { formatGqlDate } from "../utils";

export default (enquireUnit) => ({
  init() {
    if (enquireUnit) {
      this.toggleSelectedUnits(enquireUnit);
    }
    setTimeout(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.$refs.autocomplete,
        {
          types: ["geocode"],
          componentRestrictions: { country: "nz" },
        }
      );
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        this.parseAddressComponents(place.address_components);
      });
    }, 100);
  },
  whenAreYouLookingToBuy: "",
  submittedSuccessfully: false,
  appliedRegion: null,
  submitting: false,
  message: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  prebuiltVersionsInterestedIn: [],
  enquiringAboutThisSpecificModel:"",
  preferredContactMethod: "email",
  alreadyOwnSite: "",
  selectedUnits: [],
  exploreFinanceOptions: "",
  addressLine1: "",
  addressLine2: "",
  suburbTown: "",
  cityRegion: "",
  postCode: "",
  toggleSelectedUnits(unit) {
    const unitExists = this.selectedUnits.some(
      (selectedUnit) => selectedUnit.id === unit.id
    );
    if (unitExists) {
      this.selectedUnits = this.selectedUnits.filter(
        (selectedUnit) => selectedUnit.id !== unit.id
      );
    } else {
      this.selectedUnits = [...this.selectedUnits, unit];
    }
  }, 
  parseAddressComponents(components) {
    this.addressLine1 = "";
    this.addressLine2 = "";
    this.suburbTown = "";
    this.cityRegion = "";
    this.postCode = "";

    components.forEach((component) => {
      const types = component.types;

      if (types.includes("street_number")) {
        this.addressLine1 = `${component.long_name}`;
      } else if (types.includes("route")) {
        this.addressLine2 += component.long_name;
      } else if (types.includes("sublocality")) {
        this.suburbTown = component.long_name;
      } else if (types.includes("administrative_area_level_1")) {
        this.cityRegion = component.long_name;
      } else if (types.includes("postal_code")) {
        this.postCode = component.long_name;
      }
    });
  },
  errors: [],
  submitForm() {
    let variables = {};

    variables = {
      message: this.message,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      preferredContactMethod: this.preferredContactMethod,
      appliedRegion: this.appliedRegion ? [Number(this.appliedRegion)] : [],
      siteAddress: {
        address1: this.addressLine1 ?? "",
        address2: this.addressLine2 ?? "",
        city: this.suburbTown + ' ' + this.cityRegion ?? "",
        zip: this.postCode ?? "",
      },
      fullSiteAddress: `${this.addressLine1 ?? ""} ${this.addressLine2 ?? ""} ${this.suburbTown + ' ' + this.cityRegion ?? ""} ${this.postCode ?? ""}`,
      whenAreYouLookingToBuy: formatGqlDate(this.whenAreYouLookingToBuy),
      alreadyOwnSite: this.alreadyOwnSite,
      exploreFinanceOptions: this.exploreFinanceOptions,
      enquiringAboutThisSpecificModel: this.enquiringAboutThisSpecificModel,
      prebuiltVersionsInterestedIn: this.prebuiltVersionsInterestedIn.join(', ')
    };

    const domainWithProtocol = `${window.location.protocol}//${window.location.host}`;
    const client = new GraphQLClient(`${domainWithProtocol}/api`);

    const query = gql`
      mutation SubmitEnquiry(
        $firstName: String
        $lastName: String
        $email: String
        $phone: String
        $message: String
        $preferredContactMethod: String
        $appliedRegion: [Int]
        $siteAddress: enquireUs_siteAddress_FormieAddressInput
        $selectedUnits: [Int]
        $whenAreYouLookingToBuy: DateTime
        $alreadyOwnSite: String
        $enquiringAboutThisSpecificModel: String
        $prebuiltVersionsInterestedIn: String
        $exploreFinanceOptions: String
        $fullSiteAddress: String
      ) {
        save_enquireUs_Submission(
          firstName: $firstName
          lastName: $lastName
          email: $email
          phone: $phone
          message: $message
          preferredMethod: $preferredContactMethod
          appliedRegion: $appliedRegion
          siteAddress: $siteAddress
          selectedUnits: $selectedUnits
          whenAreYouLookingToBuy: $whenAreYouLookingToBuy
          alreadyOwnSite: $alreadyOwnSite
          enquiringAboutThisSpecificModel: $enquiringAboutThisSpecificModel
          prebuiltVersionsInterestedIn: $prebuiltVersionsInterestedIn
          exploreFinanceOptions: $exploreFinanceOptions
          fullSiteAddress: $fullSiteAddress
        ) {
          id
        }
      }
    `;

    this.submitting = true;
    this.submittedSuccessfully = false;
    this.errors = [];
    client
      .request(query, variables)
      .then((data) => {
        this.submitting = false;
        this.submittedSuccessfully = true;
      })
      .catch((error) => {
        const errorsArray = error.response?.errors;
        errorsArray?.forEach((err) => {
          try {
            const errorDetails = JSON.parse(err.message);
            for (const [key, value] of Object.entries(errorDetails)) {
              this.errors.push(value);
            }
          } catch (parseError) {
            // If JSON.parse fails, push a generic error message
            this.errors.push("An error occurred. Please try again.");
          }
        });

        this.errors = this.errors;
        this.submitting = false;
      });
  },
});

import Swiper from "swiper/bundle";

export default (subMenu) => ({
  listeners: [], // Track event listeners for cleanup

  init() {
    if (subMenu) this.subMenu = subMenu;

    this.imageModal = [];
    const elements = document.querySelectorAll("[data-image-modal]");

    // Parse data and store index during initialization
    elements.forEach((element, index) => {
      try {
        const modalData = JSON.parse(element.getAttribute("data-image-modal"));
        this.imageModal.push(modalData);
      } catch (error) {
        console.error("Failed to parse data-image-modal:", error);
      }

      // Store reference to click handler for cleanup
      const onClick = () => this.openModal(index);
      element.addEventListener("click", onClick);
      this.listeners.push({ element, handler: onClick });
    });
  },

  openModal(activeIndex) {
    // Destroy existing Swiper instance
    if (this.imageModalSlider) {
      this.imageModalSlider.destroy(true, true);
      this.imageModalSlider = null;
    }

    // Initialize new Swiper
    this.imageModalSlider = new Swiper(".imageModalSlider", {
      slidesPerView: 1,
      spaceBetween: 20,
      centeredSlides: true,
      loop: true,
      navigation: { nextEl: ".swiper-next", prevEl: ".swiper-prev" },
      pagination: { el: ".swiper-cards-pagination", clickable: true },
      breakpoints: { 768: { spaceBetween: 48 } }
    });

    this.imageModalSlider.slideTo(activeIndex);

    // Show modal
    if (this.$refs?.imageModalRef) {
      this.$refs.imageModalRef.showModal();
    } else {
      console.error("imageModalRef not available");
    }
  },

  // Cleanup when component is destroyed
  destroy() {
    // Remove event listeners
    this.listeners.forEach(({ element, handler }) => {
      element.removeEventListener("click", handler);
    });
    this.listeners = [];

    // Destroy Swiper
    if (this.imageModalSlider) {
      this.imageModalSlider.destroy(true, true);
      this.imageModalSlider = null;
    }
  },

  // State variables
  isTestimonialModalOpen: false,
  menu: false,
  subMenu: [],
  search: false,
  imageModal: [],
  imageModalSlider: null,
});
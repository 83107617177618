export default () => ({
  init() {
    const params = new URLSearchParams(window.location.search);
    if (params.get("sortBy")) this.sortBy = params.get("sortBy");
    if (params.get("region")) {
      this.appliedRegion = params.get("region");
    }
    if (params.get("sortOrder")) this.sortOrder = params.get("sortOrder");
  },
  open: false,
  sortBy: "price",
  sortOrder: "asc",
  appliedRegion: null,
  sortByOptions: [
    { value: "price", label: "Price" },
    { value: "area", label: "Area (m²)" },
    { value: "roofspanLength", label: "Length (m)" },
    { value: "numberOfBedrooms", label: "Number of Bedrooms" },
  ],
  sortOrderOptions: [
    { value: "asc", label: "Ascending" },
    { value: "desc", label: "Descending" },
  ],
  updateUrl() {
    const params = new URLSearchParams();
    const { sortBy, sortOrder, appliedRegion } = this;

    if (sortBy !== "default") {
      params.set("sortBy", sortBy);
    }

    params.set("sortOrder", sortOrder);

    if(appliedRegion){
      params.set("region", appliedRegion);
    }

    const queryString = `?${params.toString()}`;
    const newLocation = `${location.origin}${location.pathname}${queryString}`;

    history.replaceState(null, "", queryString);

    // Show a loader
    const oldListingContainer = document.querySelector(
      "[data-filtered-content]"
    );
    if (oldListingContainer) {
      const loader = document.createElement("div");
      loader.setAttribute("class", "loader");
      oldListingContainer.prepend(loader);

      fetch(newLocation)
        .then((response) => response.text())
        .then((data) => {
          const parser = new DOMParser();
          const htmlResponse = parser.parseFromString(data, "text/html");
          const newListingContainer = htmlResponse.querySelector(
            "[data-filtered-content]"
          );

          if (newListingContainer && oldListingContainer) {
            oldListingContainer.innerHTML = newListingContainer.innerHTML;
          }
        })
        .catch((error) => {
          console.error("Error fetching new content:", error);
        })
        .finally(() => {
          if (loader.parentNode) {
            loader.parentNode.removeChild(loader);
          }
        });
    } else {
      console.warn("Old listing container not found.");
    }
  },
  clearFilters() {
    this.updateUrl();
  },
});

function getUniqueFaqCategories(faqEntries) {
    const categoriesSet = new Set();

    faqEntries.forEach(entry => {
        entry.faqCategories.forEach(category => {
            categoriesSet.add(JSON.stringify({ id: category.id, title: category.title }));
        });
    });

    const uniqueCategories = Array.from(categoriesSet).map(categoryStr => JSON.parse(categoryStr));
    
    return uniqueCategories;
}

export default (faqs) => ({
    init(){
        this.$watch('selectedCategory', () => {
            if(this.selectedCategory === null){
                return
            }
            if(this.selectedCategory === "all"){
                this.filteredFaqs = this.faqs;
                this.pageNo =0; // Reset to first page
                return
            }
            this.filteredFaqs = this.faqs.filter(entry => entry.faqCategories.some(category => category.id === this.selectedCategory));
            this.pageNo =0; // Reset to first page
        });
        this.$watch('searchText', () => {
            if(this.searchText.length){
                this.selectedCategory = null; 
            }
            if(this.searchText.length == 0 && this.selectedCategory === null){
                this.selectedCategory = 'all';
                return 
            }           
            this.filteredFaqs = this.faqs.filter(entry => entry.title.toLowerCase().includes(this.searchText.toLowerCase()) || 
            entry.answer.toLowerCase().includes(this.searchText.toLowerCase()));
            this.pageNo =0; // Reset to first page
        });
    },
    faqs: faqs ?? [],
    filteredFaqs: faqs ?? [],
    categories: getUniqueFaqCategories(faqs ?? []),
    selectedCategory: 'all',
    searchText: '',
    pageNo: 0,
    itemsPerPage: 10, // Number of FAQs per page
    get paginatedFaqs() {
        const start = Number(this.pageNo) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.filteredFaqs.slice(start, end);
    },
    nextPage() {
        if ((Number(this.pageNo) + 1) * this.itemsPerPage < this.filteredFaqs.length) {
            this.pageNo++;
        }
    },
    prevPage() {
        if (Number(this.pageNo) > 0) {
            this.pageNo--;
        }
    },
    firstPage() {
        this.pageNo =0;
    },
    lastPage() {
        this.pageNo =Math.ceil(this.filteredFaqs.length / this.itemsPerPage) - 1;
    },
    get paginationOptions() {
        const totalPages = Math.ceil(this.filteredFaqs.length / this.itemsPerPage);
        return Array.from({ length: totalPages }, (_, i) => {
            const start = i * this.itemsPerPage + 1;
            const end = Math.min((i + 1) * this.itemsPerPage, this.filteredFaqs.length);
            return { pageNumber: i, label: `${start}-${end}` };
        });
    },
    get currentRange() {
        if (this.filteredFaqs.length === 0) {
            return `Showing 0-0 of 0`;
        }
        const start = Number(this.pageNo) * this.itemsPerPage + 1;
        const end = Math.min((Number(this.pageNo) + 1) * this.itemsPerPage, this.filteredFaqs.length);
        return `Showing ${start}-${end} of ${this.filteredFaqs.length}`;
    },
    goToPage(pageNumber) {
        if (pageNumber >= 0 && pageNumber < this.paginationOptions.length) {
            this.pageNo =pageNumber;
        }
    },
    get disablePrevPage() {
        return Number(this.pageNo) === 0;
    },
    get disableNextPage() {
        return (Number(this.pageNo) + 1) * this.itemsPerPage >= this.filteredFaqs.length;
    }
});

/**
 * Utilities
 */

import debounce from "lodash/debounce";

const isDev = (process.env.NODE_ENV !== 'production');

function formatGqlDate(dateString) {
    // Create a Date object from the date string
    const date = new Date(dateString);
    
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return null
    }
    
    return date.toISOString();
  }
  
export {
    debounce,
    isDev,
    formatGqlDate
};


// Vendor imports
import Alpine from "alpinejs";
import { Fancybox } from "@fancyapps/ui";
import collapse from "@alpinejs/collapse";
import focus from "@alpinejs/focus";
import intersect from "@alpinejs/intersect";
import breakpoint from "alpinejs-breakpoints";
import ourRangeListing from "./modules/our-range-listing";
import select from "./modules/select";
import faqs from "./modules/faqs";
import faqsTypeahead from "./modules/faqs-typeahead";
import { pricingStore, shortlistHomeStore } from "./modules/alpine-stores";
import hireListing from "./modules/hire-listing";
import faqsListing from "./modules/faqs-listing";
import enquireNowForm from "./modules/enquire-now-form";
import base from "./modules/base";
import anchor from '@alpinejs/anchor'
 

Alpine.plugin(intersect);
Alpine.plugin(breakpoint);
Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.plugin(anchor)

Alpine.store("shortlistedHomes", shortlistHomeStore);
Alpine.store("pricingStore", pricingStore);

Alpine.data("base", base);
Alpine.data("ourRangeListing", ourRangeListing);
Alpine.data("hireListing", hireListing);

Alpine.data("select", select);
Alpine.data("faqs", faqs);
Alpine.data("faqsTypeahead", faqsTypeahead);
Alpine.data("faqsListing", faqsListing);
Alpine.data("enquireNowForm", enquireNowForm);

window.Alpine = Alpine;
window.AlpineBreakpointPluginBreakpointsList = [
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "2xl",
  "3xl",
];

import "lazysizes/plugins/respimg/ls.respimg";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes";
import "lazysizes/plugins/object-fit/ls.object-fit";

// Module imports
import "./modules/alerts";
import "./modules/carousel";
import "./modules/filters";
import "./modules/headroom";
import "./modules/svgIconSprite";

window.Alpine.start();

import Headroom from "headroom.js";

let options = {
	offset: {
		up: 50,
		down: 100,
	},
	tolerance: 0,
	classes: {
		initial: "pointer-events-auto",
      top : "bg-transparent",
		notTop: "bg-white",
		pinned: "bg-white",
      unpinned: "-translate-y-full pointer-events-none opacity=- nav-pinned"
	},
}

let options2 = {
	offset: {
		up: 50,
		down: 100,
	},
	tolerance: 0,
	classes: {
		initial: "top-[var(--p-header)]",
		pinned: "top-[var(--p-header)]",
		unpinned: "top-0",
	},
}

let options3 = {
	offset: {
		up: 50,
		down: 100,
	},
	tolerance: 0,
	classes: {
		initial: "xl:top-[calc(var(--p-header)+6rem)]",
		pinned: "xl:top-[calc(var(--p-header)+6rem)]",
		unpinned: "xl:top-24",
	},
}

let header = document.querySelector("header")
let pageNav = document.querySelector("#page-nav")
let root = document.querySelector(":root")
let rangeDetailSidebar = document.querySelector("#rangeDetailSidebar")

if (header) {
	let headroom = new Headroom(header, options)
   headroom.init()
}

if (pageNav) {
   let sticky = new Headroom(pageNav, options2)
   sticky.init()
}

if (rangeDetailSidebar) {
   let sticky = new Headroom(rangeDetailSidebar, options3)
   sticky.init()
}

if (header) {
	root.style.setProperty("--p-header", `${header.getBoundingClientRect().height}px`)
	window.addEventListener("resize", () => {
		root.style.setProperty("--p-header", `${header.getBoundingClientRect().height}px`)
	})
}

// const parallax = document.querySelector("#trees");

// if (parallax && window.matchMedia("(min-width: 1280px)")) {
// 	window.addEventListener("scroll", () => {
// 		let offset = window.scrollY;
// 		parallax.style.transform = `translateY(-${offset * 0.05}px)`
// 	});
// }
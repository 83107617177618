import Alpine from "alpinejs";
import { isArray, update } from "lodash";

export const shortlistHomeStore = {
  init() {
    this.shortlist = window.shortlist || [];
  },
  shortlist: [],
  toggleShortlist(productId) {
    // Check if productId is already in the shortlist
    if (this.isShortlisted(productId)) {
      // If it is, remove it from the shortlist
      this.shortlist = this.shortlist.filter(s => s.productId !== productId);
    } else {
      // If it's not, add it to the shortlist
      this.shortlist.push({productId: productId, deliveryZoneId: null});
    }

    // Send updated shortlist to server to update the cookie
    this.updateShortlistCookie();
  },
  async removeShortlistHome(index) {
    this.shortlist = this.shortlist.filter((s,i) => i !== index);
    this.shortlist = this.shortlist;

    // Send updated shortlist to server to update the cookie
   await this.updateShortlistCookie();
   await this.updateShortlistHTML();
  },
  async moveShortlistHome(index, move) {
    // Check if the move is within valid bounds
    const newIndex = index + move;
    
    if (newIndex >= 0 && newIndex < this.shortlist.length) {
      // Swap the elements in the array
      [this.shortlist[index], this.shortlist[newIndex]] = 
        [this.shortlist[newIndex], this.shortlist[index]];
  
      // Update the shortlist cookie on the server
      await this.updateShortlistCookie();
      await this.updateShortlistHTML();
    } else {
      console.warn("Move out of bounds");
    }
  },
  async duplicateShortlistHome(index) {

    this.shortlist.push({...this.shortlist[index]});

    this.shortlist = this.shortlist;
    // Send updated shortlist to server to update the cookie
    await this.updateShortlistCookie();
    await this.updateShortlistHTML();
  },
  isShortlisted(productId) {
    return !!this.shortlist.find(s=>s.productId === productId);
  },
  async updateDeliveryZoneId(index, deliveryZoneId){
    this.shortlist[index].deliveryZoneId = deliveryZoneId;
    this.shortlist = this.shortlist;
    await this.updateShortlistCookie();
    await this.updateShortlistHTML();

  },
  async updateEtaOption(index, etaOption){
    this.shortlist[index].etaOption = etaOption;
    this.shortlist = this.shortlist;
    await this.updateShortlistCookie();
  },
  async updateDateEta(index, dateEta){
    this.shortlist[index].dateEta = dateEta;
    this.shortlist = this.shortlist;
    await this.updateShortlistCookie();
  },
  async updateExtras(index, extras){
    this.shortlist[index].extras = extras;
    this.shortlist = this.shortlist;
    await this.updateShortlistCookie();
  },
  async updateShortlistCookie() {
    const domainWithProtocol = `${window.location.protocol}//${window.location.host}`;
    // Send the POST request to update the cookie on the server
    try {
      const response = await fetch(`${domainWithProtocol}/actions/_cohorts/shortlist/set-cookie`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': window.csrfToken
        },
        body: JSON.stringify({ shortlist: isArray(this.shortlist) ? this.shortlist : [] })
      });
      const data = await response.json();
      if (data.success) {
        // console.log('Shortlist cookie updated successfully');
      } else {
        console.error('Error updating shortlist cookie:', data.error);
      }
    } catch (error) {
      console.error('Error during cookie update request:', error);
    }
  },
  async updateShortlistHTML(){

    const newLocation = `${location.origin}${location.pathname}`;

    const oldListingContainer = document.querySelector(
      "[data-shortlist-content]"
    );
    
    if (oldListingContainer) {
      const loader = document.createElement("div");
      loader.setAttribute("class", "loader");
      oldListingContainer.prepend(loader);

      fetch(newLocation)
        .then((response) => response.text())
        .then((data) => {
          const parser = new DOMParser();
          const htmlResponse = parser.parseFromString(data, "text/html");
          const newListingContainer = htmlResponse.querySelector(
            "[data-shortlist-content]"
          );

          if (newListingContainer && oldListingContainer) {
            oldListingContainer.innerHTML = newListingContainer.innerHTML;
          }
        })
        .catch((error) => {
          console.error("Error fetching new content:", error);
        })
        .finally(() => {
          if (loader.parentNode) {
            loader.parentNode.removeChild(loader);
          }
        });
    } else {
      console.warn("Old listing container not found.");
    }
  }
};


export const pricingStore = {
  init() {
    const pricing = JSON.parse(localStorage.getItem("pricing"))
    this.showFinancePrice = pricing?.showFinancePrice ?? false;
    
    Alpine.effect(()=>{
      localStorage.setItem(
        "pricing",
        JSON.stringify({
          showFinancePrice: this.showFinancePrice,
        })
      );
    })
  },
  showFinancePrice: false,
  toggleShowFinancePrice() {
    this.showFinancePrice = !this.showFinancePrice;
  },

  getPrice(price) {
    const cleanedString = price.replace(/,/g, "");
    let finalPrice = parseFloat(cleanedString);
    return this.formatPrice(finalPrice.toFixed(2));
  }, 
  formatPrice(price){
    let priceToFloat = parseFloat(price);
    if(isNaN(priceToFloat)){
      return ''
    }
    return new Intl.NumberFormat("en-US").format((priceToFloat).toFixed(2))
  },
  addPrice(prices){
    const total = prices.reduce((sum, price) => {
      const cleanedString = price?.replace(/,/g, "");
      const number = parseFloat(cleanedString);
      if (isNaN(number)) {
        return sum;
      }
      return sum + number;
    }, 0);
    return new Intl.NumberFormat("en-US").format((total).toFixed(2))
  },
  getExcGST(price){
    const cleanedString = price?.replace(/,/g, "");
    let principal = parseFloat(cleanedString);
    let finalPrice = principal - principal * (15 / 100);

    return this.formatPrice(finalPrice.toFixed(2));  }
};

import { GraphQLClient, gql } from "graphql-request";

export default (search = '') => ({
    init(){
        this.searchText = search ?? '';
        this.$watch('searchText', (searchText) => {
            this.getFaqs(searchText)
         })
    },

  getFaqs(search) {
    if(search.length == 0){
        this.faqs = []
        return 
    }
    const domainWithProtocol = `${window.location.protocol}//${window.location.host}`;
    const client = new GraphQLClient(`${domainWithProtocol}/api`);
    
    const query = gql`
      query Faqs($search: String) {
        faqsEntries(search: $search, limit: 5) {
          ... on faqs_Entry {
            title
            url
          }
        }
      }
    `;

    client
      .request(query, {search})
      .then((data) => {
        this.faqs = data?.faqsEntries ?? []})
      .catch((error) => console.error(error));
  },
  faqs: [],
  searchText: "",
  navigateToSearch() {
    const searchUrl = `?search=${encodeURIComponent(this.searchText)}&page=1`;
    window.location.href = searchUrl;
},
});

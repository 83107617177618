export default (tab) => ({
  init() {
    if(tab?.trim()?.length){
        this.selectedTab = tab;
    }
  },
  selectedTab: "purchasing",
  tabs: [
    { label: "Purchasing", id: "purchasing" },
    { label: "Renting", id: "renting" },
  ],
  updateUrl() {
    const params = new URLSearchParams();
    const { selectedTab } = this;

    params.set("tab", selectedTab);

    const queryString = `?${params.toString()}`;
    const newLocation = `${location.origin}${location.pathname}${queryString}`;

    window.location.href = newLocation;
  },
});

import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

var singleCarousel = new Swiper(".single-carousel", {
	slidesPerView: 1,
	speed: 2000,
	navigation: {
		nextEl: ".swiper-next",
		prevEl: ".swiper-prev",
	},
	pagination: {
		el: ".swiper-pagination",
		clickable: true
	},
});

var heroCarousel = new Swiper(".hero-carousel", {
	slidesPerView: 1,
    effect: "fade",
	loop: true,
    centeredSlides: true,
    fadeEffect: { crossFade: true },
        autoplay: {
			pauseOnMouseEnter: false,
			waitForTransition: false,
			stopOnLastSlide: false,
			disableOnInteraction: false,
          delay: 4000,
        },
});


var freeformCards = new Swiper(".freeform-cards", {
	slidesPerView: 'auto',
	centeredSlides: false,
	spaceBetween: 20,
	// loop: true,
	navigation: {
		nextEl: ".swiper-next",
		prevEl: ".swiper-prev",
	},
	pagination: {
		el: ".swiper-cards-pagination",
		clickable: true
	},
	breakpoints: {
		768: {
			spaceBetween: 48
		}
	}
});

var freeformCardsInner = new Swiper(".freeform-cards-inner", {
	slidesPerView: "auto",
	spaceBetween: 20,
	slidesOffsetBefore: 20,
	slidesOffsetAfter: 20,
	navigation: {
		nextEl: ".swiper-next",
		prevEl: ".swiper-prev",
	},
	pagination: {
		el: ".swiper-pagination",
	},
	breakpoints: {
		768: {
			spaceBetween: 48,
			slidesOffsetBefore: 40,
			slidesOffsetAfter: 40,
		},
		1024: {
			spaceBetween: 48,
			slidesOffsetBefore: 80,
			slidesOffsetAfter: 80,
		},
		1280: {
			spaceBetween: 48,
			slidesOffsetBefore: 112,
			slidesOffsetAfter: 112,
		},
		1600: {
			spaceBetween: 48,
			slidesOffsetBefore: 348,
			slidesOffsetAfter: 348,
		}
	}
});

var threeCards = new Swiper(".three-cards", {
	slidesPerView: "1.25",
	centeredSlides: true,
	spaceBetween: 24,
	loop: true,
	breakpoints: {
		768: {
			slidesPerView: "2",
			spaceBetween: 48,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 64,
		},
		1280: {
			slidesPerView: 3,
			spaceBetween: 0,
			centeredSlides: false
		}
	},
	navigation: {
		nextEl: ".swiper-next",
		prevEl: ".swiper-prev",
	},
	pagination: {
		el: ".swiper-pagination",
		clickable: true
	},
});

var cbCarousel = new Swiper(".cb-carousel", {
	slidesPerView: "1.25",
	spaceBetween: 24,
	breakpoints: {
		768: {
			slidesPerView: "2",
			spaceBetween: 32,
		},
		1024: {
			slidesPerView: "2.5",
			spaceBetween: 32,
		},
		1280: {
			slidesPerView: "2.5",
			spaceBetween: 48,
			centeredSlides: false
		}
	},
	navigation: {
		nextEl: ".cb-carousel-next",
		prevEl: ".cb-carousel-prev",
	}
});